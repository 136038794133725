import api from "./api";
const experiencias = fetch('mocks' + import.meta.env.VITE_BASE_PATH + 'experiencia.json')
var jsonFinal = "" 
class ExperienciasService {
  async get(filters) {
    if(!jsonFinal){
      jsonFinal = [...await ((await experiencias).json())]
    }
    let experienciasReturn = jsonFinal
    if(filters.hasOwnProperty("isHomeHighlighted"))
      experienciasReturn = experienciasReturn.filter(experiencia => experiencia.isHomeHighlighted === filters.isHomeHighlighted);
    if(filters.hasOwnProperty("menuId") && filters.menuId)
    experienciasReturn = experienciasReturn.filter(experiencia => experiencia.menuId === filters.menuId);
      
    if(filters.hasOwnProperty("tagId") && filters.tagId)
      experienciasReturn = experienciasReturn.filter(expe => {
        if(expe.hasOwnProperty("tagIds"))
          for(let tag of expe.tagIds)
            if(tag === parseInt(filters.tagId))
              return true;

        return false;
      });

    if(filters.hasOwnProperty("search") && filters.search)
      experienciasReturn = experienciasReturn.filter(expe => (expe.title.toUpperCase().indexOf(filters.search.toUpperCase()) >= 0));
    
    if(filters.hasOwnProperty("limit") && filters.limit)
      experienciasReturn = experienciasReturn.slice(0, filters.limit);
      experienciasReturn = experienciasReturn.sort((a, b) => b.id - a.id)
    return experienciasReturn;
  }
}

export default new ExperienciasService();
