<script>
import FormInput from '@/components/form/FormInput.vue';
import AppButton from '@/components/ui/AppButton.vue';
import Icons from '@/components/Icons.vue';
import BoxProd from '@/components/BoxProd.vue';
import Modal from '@/components/Modal.vue';
import ProductsForm from '@/components/ProductsForm.vue';
export default {
	name: 'Entrar',
	components: {
		FormInput,
		AppButton,
		Icons,
		BoxProd,
		Modal,
		ProductsForm
	},
	created() {
		this.user = {
			email: this.$store.state?.auth?.user.email,
			name: this.$store.state?.auth?.user.name,
			lastName: this.$store.state?.auth?.user.lastName,
			birthday: this.getDateFormated(this.$store.state?.auth?.user?.birthday),
			member: {
				id:  this.$store.state?.auth?.user?.member?.id,
				cellphone: this.$store.state?.auth?.user?.member?.cellphone 
			}
		}
		this.getProducts();
		console.log(this.$store.state?.auth?.user?.birthday)
	},
	data() {
		return {
			user: {},
			products: [],
			editing: false,
			isOpen: false,
			newProductOpen: false,
			dont: true,
			changed: false,
			dont: true,
			observer: null
		}
	},
	methods: {
		getDateFormated(dt){
			if(dt.indexOf('T') > 0)
				return dt.substring(0, dt.indexOf('T'))

			return dt;
		},
		getProducts() {
			if (this.user?.member?.id) {
				this.changed = false; 
				this.products = [] 
				this.$store.dispatch("product/getMemberProducts", this.user.member.id).then(
					(resp) => {
						this.newProductOpen = false
						this.products = resp.data;
						if(!this.dont){
							this.resetSlick()
							var self = this;
							setTimeout(function() {
								self.slideProdutos();
							}, 500);
						}
					},
					(error) => {
						console.log(error)
					}
				).catch(error => {
					console.log(error)
				});
			}
		},
		resetSlick(){
			if (this.$refs.next.classList.contains('slick-arrow'))
				this.$refs.next.classList.remove('slick-arrow');
			if (this.$refs.prev.classList.contains('slick-arrow'))
				this.$refs.prev.classList.remove('slick-arrow');
			if (this.$refs.slickPai.classList.contains('slick-initialized'))
				this.$refs.slickPai.classList.remove('slick-initialized');
			if (this.$refs.slickPai.classList.contains('slick-slider'))
				this.$refs.slickPai.classList.remove('slick-slider');
			const removeDrag = this.$refs.slickPai.querySelector('.slick-list.draggable')
			if(removeDrag !== null)
				removeDrag.parentNode.removeChild(removeDrag);
		},
		slideProdutos() {
			$('.produtosList').each(function (index, sliderWrap) {
				var $slider = $(sliderWrap).find('.products--content');
				var $next = $(sliderWrap).find('.next');
				var $prev = $(sliderWrap).find('.prev');
				$slider.slick({
					slidesToShow: 2,
					slidesToScroll: 1,
					fade: false,
					autoplay: false,
					infinite: true,
					autoplaySpeed: 3000,
					speed: 400,
					cssEase: 'ease-in-out',
					nextArrow: $next,
					prevArrow: $prev,
					dots: false,

					responsive: [{
						breakpoint: 768,
						settings: {
							dots: false,
							arrows: true,
							slidesToShow: 1,
						}
					}]
				});
				// Atualiza o contador após a mudança de slide
				$slider.on('afterChange', (event, slick, currentSlide) => {
					var totalSlides = slick.slideCount;
					var currentSlideNumber = currentSlide + 1;
					var counterText = currentSlideNumber + '/' + totalSlides;
					$(sliderWrap).find('.counter').text(counterText);
				});

				// Atualiza o contador na inicialização
				var totalSlides = $slider.slick('getSlick').slideCount;
				var currentSlideNumber = $slider.slick('slickCurrentSlide') + 1;
				var counterText = currentSlideNumber + '/' + totalSlides;
				$(sliderWrap).find('.counter').text(counterText);
			});
			this.changed = true;
		},

		openModal() {
			this.isOpen = true;
		},
		closeModal() {
			this.isOpen = false;
		},
		callbackProduct(sts){
			if(sts === true)
				this.getProducts()
		},
		updateMember(e){
			e.preventDefault()
			this.$store.dispatch('user/update', this.user)
			.then(resp => {
				this.editing = false
				this.$store.dispatch('auth/updateUser', this.user).then(() => {
					console.log(this.$store.state?.auth?.user?.birthday)
				})
				console.log(resp)
			})
			.catch(e => {
				console.log(e)
			})
		}
	
},
mounted() {
	var self=this;
	this.observer = new MutationObserver(function(mutations) {
		var self = this;
		if(document.querySelectorAll('.products--content').length > 0 && self.dont){
			self.dont = false;
			self.slideProdutos();
		}
	}.bind(this));
	// Setup the observer
	this.observer.observe(
		document.querySelectorAll('.products--content')[0],
		{ attributes: true, childList: true, characterData: true, subtree: true }
	);
},

}

</script>

<template>
	<section class="wrapper">
		<!-- MODAL PRODUTO =============== -->
		<Modal :is-open="newProductOpen" @close="newProductOpen = false" >
			<template v-slot:title>{{ $t('myArea.modalAlertTitle') }}</template> 
			<div class="content--prod">
			
				<ProductsForm @send="callbackProduct" @close="newProductOpen = false" />
			</div>
			
		</Modal>
		<!-- MODAL PRODUTO =============== -->


		<!-- MODAL ALERTA =============== -->
		<Modal :is-open="isOpen" @close="closeModal">
			<template v-slot:title>{{ $t('myArea.modalAlertTitle') }}</template>
			<div class="content--alert">
				<p>{{ $t('myArea.modalAlertText') }}</p>		
			</div>
			<div class="buttons--wrap">
				<AppButton variant="black">{{ $t('myArea.ok') }}</AppButton>
			</div>
		</Modal>
		<!-- MODAL ALERTA =============== -->

		<!-- MODAL ALERTA DELETAR =============== -->
		<Modal :is-open="isOpen" @close="closeModal">
			<template v-slot:title>{{ $t('myArea.modalDeletTitle') }}</template>
			<div class="content--alert" v-html="$t('myArea.modalDeletText')">

			</div>
			<div class="buttons--wrap">
				<AppButton variant="secondary">{{ $t('buttons.cancelar') }}</AppButton>
				<AppButton variant="primary">{{ $t('buttons.excluir') }}</AppButton>
			</div>
		</Modal>
		<!-- MODAL ALERTA DELETAR =============== -->

		<!-- MODAL ALERTA DELETAR =============== -->
		<Modal :is-open="isOpen" @close="closeModal">
			<template v-slot:title>{{ $t('myArea.modalDeletTitle') }}</template>
			<div class="content--alert">
				<p>{{ $t('myArea.modalDeletTextConfirm') }}</p>
			</div>
			<div class="buttons--wrap">
				<AppButton variant="black">{{ $t('myArea.ok') }}</AppButton>
			</div>
		</Modal>
		<!-- MODAL ALERTA DELETAR =============== -->






		<div class="infos">
			<hgroup>
				<h1>{{ $t('myArea.title') }}</h1>
			</hgroup>
			<form class="grid">
				<div class="row">

					<FormInput v-model="this.user.email" :label="$t('form.labels.mail')"  inputId="mail" inputType="email" :placeholder="$t('form.placeholder.email')" :isRequired="true" :col="'col-100'" :isDisabled="true" />
					<FormInput v-model="this.user.name" :label="$t('form.labels.name')" inputId="nome" inputType="text" :placeholder="$t('form.placeholder.name')" :isRequired="true" :col="'col-100'" :isDisabled="!editing" />
					<FormInput v-model="this.user.lastName" :label="$t('form.labels.sobrenome')"  inputId="sobrenome" inputType="text" :placeholder="$t('form.placeholder.nameLast')" :isRequired="true" :col="'col-100'" :isDisabled="!editing" />
					<FormInput v-model="this.user.birthday" :label="$t('form.labels.nascimento')" inputId="data" inputType="date" :isRequired="true" :col="'col-100'" :isDisabled="!editing" />
					<FormInput v-model="this.user.member.cellphone" :label="$t('form.labels.celular')" inputId="tel" inputType="tel" :placeholder="$t('form.placeholder.tel')" :isRequired="true" :col="'col-100'" :isDisabled="!editing" />
						{{user.birthday.substring(0, user.birthday.indexOf('T'))}}
				</div>
				<div class="row buttons--wrap">
					<AppButton @click="$event.preventDefault(); editing = !editing" v-show="!editing" small variant="secondary">{{ $t('buttons.edit') }}</AppButton>
					<AppButton @click="updateMember($event)" variant="secondary" v-show="editing" small>{{ $t('buttons.salvar') }}</AppButton>
					<!--<AppButton @click="updateMember($event)" variant="primary" v-show="editing" small>{{ $t('buttons.cancelar') }}</AppButton>-->
				</div>
			</form>
		</div>
		<section class="produtosList">
			<header>
				<h2>{{ $t('myArea.titleProd') }}</h2>
				<div class="contentHeader--produtosList">
					<div class="counter" ref="counter"></div>
					<div class="btnCarrossel">
						<button ref="prev" class="arrow-slick-slide prev">
							<Icons name="arrowLeft" />
						</button>
						<button ref="next" class="arrow-slick-slide next">
							<Icons name="arrowRight" />
						</button>
					</div>
				</div>
			</header>
			<div class="prodsWrapper">
				<p v-if="products.length <= 0" class="prodsEmpty">{{ $t('myArea.textMSGProd') }}</p>
				<AppButton variant="secondary" small @click="newProductOpen = true">{{ $t('buttons.addProd') }}</AppButton>
				<div v-show="changed" ref="slickPai" class="products--content">
					<BoxProd v-for="product in products" :key="product.id" 
						:subCategory="product.product.subCategory.name" 
						:category="product.product.subCategory.category.name"
						:name="product.product.name" />

				</div>
			</div>
		</section>
	</section>
</template>

<style lang="sass" scoped>
@use '../assets/sass/utilities/_colors' as *
@use '../assets/sass/utilities/_responsive' as *
@use '../assets/sass/utilities/_mixin' as *

.wrapper
	width: min(100%, 550px)
	margin-inline: auto
	margin-block: 4rem

	hgroup
		margin-bottom: 3.125rem
		h1
			font-weight: 700
			font-size: 2rem

.infos
	padding-inline: 1rem
	+respond-to($breakpoint-sm-min)	
		padding-inline: 0

	.row
		gap: 10px
		margin-bottom: 2rem
		
		&.buttons--wrap
			justify-content: flex-end


.produtosList
	margin-top: 50px

	.contentHeader--produtosList
		display: flex
		align-items: center
		gap: 0.625rem

		.counter
			font-weight: 400
			@include font-size (1rem, 22px )

		.btnCarrossel
			display: flex
			gap: 0.3125rem


	header
		--p: 20px
		display: flex
		flex-direction: row
		justify-content: space-between
		align-items: center
		border-bottom: 1px solid var(--color-grey-1700)
		margin-bottom: var(--p)
		padding-bottom: var(--p)
		padding-inline: 1rem
		+respond-to($breakpoint-sm-min)	
			padding-inline: 0

		h2
			font-weight: 700
			font-size: 2rem

	.prodsWrapper
		padding: var(--padding-mobile)
		+respond-to($breakpoint-sm-min)
			padding: 0
	.prodsEmpty
		font-weight: 600
		margin-bottom: 1rem

.products--content
	--m-slide: 10px
	margin-top: 2rem
	
	.slick-slide 
		margin: 0 var(--m-slide)	

	.slick-list 
		margin: 0 calc(-1* var(--m-slide))

.content--prod
	width: 100%
	+respond-to($breakpoint-sm-min)
		min-width: 450px



</style>