const tags = fetch(`mocks${import.meta.env.VITE_BASE_PATH}tags.json`)
var jsonFinal = ""  
class TagsService {
  async get() {
    if(!jsonFinal){
      jsonFinal = await ((await tags).json())
    }
    return jsonFinal
  }
}

export default new TagsService()
