import { createStore } from "vuex";
import { auth } from "./auth.module";
import { product } from "./product.module";
import { bannerHome } from "./banner.home.module";
import { products } from "./products.module";
import { lancamentos } from "./lancamentos.module";
import { tutoriais } from "./tutoriais.module";
import { modalService } from "./modal.module";
import { bannerLancamentos } from "./banner.lancamentos.module";
import { experiencias } from "./experiencias.module";
import { bannerExperiencia } from "./banner.experiencia.module";
import { user } from "./user.module";
import { tags } from "./tags.module";
import { bannerAproveite } from "./banner.aproveite.module";
import { bannerAtendimento } from "./banner.atendimento.module";
import { faq } from "./faq.module";
import { analytics } from "./analytics.module";
import { socialMedias } from "./social-medias.module";
import { countries } from "./countries.module";

const store = createStore({
  modules: {
    auth,
    product,
    bannerHome,
    products,
    lancamentos,
    tutoriais,
    modalService,
    bannerLancamentos,
    experiencias,
    bannerExperiencia,
    user,
    tags,
    bannerAproveite,
    bannerAtendimento,
    faq,
    analytics,
    socialMedias,
    countries
  },
});

export default store;
