import { createI18n } from 'vue-i18n'
import langJson from './internationalization.json'

const i18n = createI18n({
    locale: 'pt-br',
    fallbackLocale: 'es',
    messages: loadInternationalization(),
})

function loadInternationalization(){
    const messages = {pt:{}, es:{}, esar:{}, esec:{}, escl:{}, esco:{}, esmx:{}, espe:{}, esps:{}}
    itObj(langJson, messages.es, messages.pt, messages.esar, messages.esec, messages.escl, messages.esco, messages.esmx, messages.espe, messages.esps);
    return messages
}

function itObj(array, es, pt, esar, esec, escl, esco, esmx, espe, esps, node){
    for (const sub in array) {
        if(typeof array[sub] === 'object'){
            let oldPt = pt;
            let oldes = es;
            let oldesar = esar;
            let oldesec = esec;
            let oldescl = escl;
            let oldesco = esco;
            let oldesmx = esmx;
            let oldespe = espe;
            let oldesps = esps;
            if(!es.hasOwnProperty(sub) && !array[sub].hasOwnProperty("es")){
                es[sub] = {}
                es = es[sub]
            }else
                node = sub
            if(!pt.hasOwnProperty(sub) && !array[sub].hasOwnProperty("pt")){
                pt[sub] = {}
                pt = pt[sub]
            }else
                node = sub
            if(!esar.hasOwnProperty(sub) && !array[sub].hasOwnProperty("esar")){
                esar[sub] = {}
                esar = esar[sub]
            }else
                node = sub  
            if(!esec.hasOwnProperty(sub) && !array[sub].hasOwnProperty("esec")){
                esec[sub] = {}
                esec = esec[sub]
            }else
                node = sub
            if(!escl.hasOwnProperty(sub) && !array[sub].hasOwnProperty("escl")){
                escl[sub] = {}
                escl = escl[sub]
            }else
                node = sub
            if(!esco.hasOwnProperty(sub) && !array[sub].hasOwnProperty("esco")){
                esco[sub] = {}
                esco = esco[sub]
            }else
                node = sub
            if(!esmx.hasOwnProperty(sub) && !array[sub].hasOwnProperty("esmx")){
                esmx[sub] = {}
                esmx = esmx[sub]
            }else
                node = sub
            if(!espe.hasOwnProperty(sub) && !array[sub].hasOwnProperty("espe")){
                espe[sub] = {}
                espe = espe[sub]
            }else
                node = sub  
            if(!esps.hasOwnProperty(sub) && !array[sub].hasOwnProperty("esps")){
                esps[sub] = {}
                esps = esps[sub]
            }else
                node = sub
            itObj(array[sub], es, pt, esar, esec, escl, esco, esmx, espe, esps, node)
            pt = oldPt
            es = oldes
            esec = oldesec;
            esar = oldesar; 
            escl = oldescl;
            esco = oldesco;
            esmx = oldesmx;
            espe = oldespe;
            esps = oldesps;
        }
        else{
            if(sub =='pt') pt[node] = array.pt;
            if(sub =='es') es[node] = array.es;
            if(sub =='esec') esec[node] = array.esec;
            if(sub =='esar') esar[node] = array.esar;
            if(sub =='escl') escl[node] = array.escl;
            if(sub =='esco') esco[node] = array.esco;
            if(sub =='esmx') esmx[node] = array.esmx;
            if(sub =='espe') espe[node] = array.espe;
            if(sub =='esps') esps[node] = array.esps;
        }
    }
}

export {i18n};
