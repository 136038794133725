import { createRouter, createWebHistory } from 'vue-router'
import AuthService from '@/services/auth.service'
import Logged from '@/views/Logged.vue'
import Signin from '@/views/Signin.vue'
import SignUp from '@/views/SignUp.vue'
import PasswordDefinition from '@/views/PasswordDefinition.vue'
import MyArea from '@/views/MyArea.vue'
import Tutoriais from '@/views/Tutoriais.vue'
import ActiveAccount from '@/views/ActiveAccount.vue'
import routeConfig from '@/locale/internationalization.json';

const components = {
    'TermsOfUse': () => import('@/views/privacy/TermsOfUse.vue'),
    'PrivacyPolicy': () => import('@/views/privacy/PrivacyPolicy.vue'),
	'PrivacyCookies': () => import('@/views/privacy/PrivacyCookies.vue'),
	'Lgpd': () => import('@/views/privacy/Lgpd.vue'),
	'Legal': () => import('@/views/privacy/Legal.vue'),
	'Accessibility': () => import('@/views/privacy/Accessibility.vue'),

};

let dynamicRoutes = [];

// Função para criar rotas dinamicamente
function createRoutes(config) {
    if (config.footer) {
        for (const key in config.footer) {
            if (config.footer[key].url && config.footer[key].component) {
                const component = components[config.footer[key].component];
                if (component) {
                    for (const lang in config.footer[key].url) {
                        if (config.footer[key].url[lang]) {
                            dynamicRoutes.push({
                                path: config.footer[key].url[lang],
                                name: `${key}-${lang}`,
                                component: component
                            });
                        }
                    }
                } else {
                    console.error(`Componente não encontrado: ${config.footer[key].component}`);
                }
            }
        }
    }
}

createRoutes(routeConfig);

const router = createRouter({
	history: createWebHistory(import.meta.env.VITE_BASE_PATH || '/'),
	linkActiveClass: 'active',
	mode: 'history',
	routes: [
		{
			path: '/',
			component: Logged,
			children: [
				{
					path: '/',
					name: 'home',
					component: () => import('@/views/Home.vue')
				},
				{
					path: '/lancamentos',
					name: 'lancamentos',
					component: () => import('@/views/Lancamento.vue')
				},
				{
					path: '/experiencias-exclusivas',
					name: 'experiencia-exclusiva',
					component: () => import('@/views/ExperienciaExclusiva.vue')
				},
				{
					path: '/buscar/:tagName?',
					name: 'buscar',
					component: () => import('@/views/Buscar.vue')
				},
				{
					path: '/evento/:id',
					name: 'evento',
					component: () => import('@/views/LaunchEvent.vue')
				},
				...dynamicRoutes // Adicionar rotas dinâmicas
				
			]
		},
		{
			path: '/entrar',
			name: 'Signin',
			component: Signin
		},
		{
			path: '/portal',
			name: 'logged',
			component: Logged,
			children: [
				{
					path: '/entrar',
					name: 'Signin',
					component: Signin
				},

				{
					path: '/cadastre-se',
					name: 'Signup',
					component: SignUp
				},
				{
					path: '/validate-user',
					name: 'ValidateUser',
					component: SignUp
				},

				{
					path: '/forgot/validate',
					name: 'PassChange',
					component: PasswordDefinition
				},
				{
					path: '/minha-area',
					name: 'MinhaArea',
					component: MyArea
				},
				{
					path: '/dicas-tutoriais',
					name: 'tutoriais',
					component: Tutoriais
				},
				{
					path: '/ativar-conta',
					name: 'ActiveAccount',
					component: ActiveAccount
				}


			]
		}
	]
})



router.beforeEach((to, from, next) => {



	const publicPages = ['', '/', '/entrar', '/ativar-conta', '/validate-user', '/redefinir-senha', '/cadastre-se', '/forgot/validate', '/termos-de-utilizacao-family-club', '/politica-de-privacidade', '/politica-de-cookies', '/legal', '/ajuda-de-acessibilidade', '/terminos-de-utilizacion-familia-club', '/accesibilidad', '/legales', '/ayuda-de-accesibilidad', '/buscar'];
	const privatePages = ['/minha-area'];
	const authRequired = !publicPages.includes(to.path);
	// const pageExists = !authRequired || privatePages.includes(to.path);
	const userLogged = AuthService.getCurrentUser();
	const loggedIn = !!userLogged
	const adminPages = ['/portal/settings'];
	const adminRequired = (adminPages.includes(to.path) && userLogged?.role?.id === 1) || !adminPages.includes(to.path);
	// trying to access a restricted page + not logged in
	// redirect to login page || !pageExists
	if ((authRequired && (!loggedIn || !adminRequired)) && (to.path.indexOf('/buscar') < 0)) {
		next('/entrar');
	} else {
		next();
	}


});


export default router