<script>
import FormInput from '@/components/form/FormInput.vue';
import AppButton from '@/components/ui/AppButton.vue';
import Checkbox from '@/components/form/Checkbox.vue';

export default {
	name: 'Entrar',
	components: {
		FormInput,
		AppButton,
		Checkbox
	},
	data() {
    return {
		accountActive: false,
		passwordReset: false,
		resetSenha: false,
      showRecover: true,
	  user: {
		email: "",
		password: ""
	  },
	  sending: false,
	  feedback: '',
	  mensagemReset: '',
	  blocked: []
    };
  },
  mounted() {
	if(this.$route.query?.resetError === 'true'){
		this.togglePass()
		this.mensagemReset = this.$route.query?.message
	}
	this.accountActive = this.$route.query?.ativarConta === 'true',
	this.resetSenha = this.$route.query?.resetarSenha === 'true'
	this.passwordReset = this.$route.query?.passwordReset === 'true'

	const queryParams = new URLSearchParams(this.$route.query);

	if (queryParams.has('extOrigin')) {
		this.createReferrer(queryParams)
	}
  },
  computed:{
	loginValidation: function(){
      return {
		email: !!this.user.email && /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(this.user.email),
		password: !!this.user.password,
	  }
    },
	resetValidation: function(){
      return {
		email: !!this.user.email && /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(this.user.email),
	  }
    },
	isValid : function(){
		var ret = true
		Object.entries(this.loginValidation).forEach(([key, value]) => {
			if(!value)
				ret = false;
		});
		return ret;
	},
	isValidReset : function(){
		var ret = true
		Object.entries(this.resetValidation).forEach(([key, value]) => {
			if(!value)
				ret = false;
		});
		return ret;
	},
  },
  methods: {
	createReferrer(queryParams){
			const externalOriginKeyValues = {
				type: queryParams.get('extOrigin'),
				keyValues: [],
			};

			// Itera sobre os query params e verifica quais começam com "ext"
			queryParams.forEach((value, key) => {
				console.log(key)
				if (key.startsWith('ext') && key != 'extOrigin') {
					externalOriginKeyValues.keyValues.push({
						value: value,
						key: key.substring(3),
					});
				}
			});
			localStorage.setItem("fam-referrer", JSON.stringify(externalOriginKeyValues));
		},
	loginAct(event){
		event.preventDefault()
		this.feedback = ''
		this.sending = true;

		if(this.isValid){
			this.$store.dispatch("auth/login", this.user)
			.then(
				(resp) => {
					this.$router.push("/");
				},
				(error) => {
					console.log(error)
					this.feedback = this.$t('feedbackMessage.userPass');
				}
			).catch(error =>{
				console.log(error)
				this.feedback = this.$t('feedbackMessage.userPass');
			});
		}
      
	},
	sendTokenEmail(event){
		event.preventDefault()
		this.sending = true
		if(this.isValidReset){
			if(!this.blocked.includes(this.user.email.toLowerCase()))
				this.$store.dispatch("auth/token", this.user.email).then(
					(resp) => {
						this.passwordReset = true
						this.togglePass()
					},
					(error) => {
						console.log(error)
						this.feedback = error.response?.data?.error?.message
						if(!this.feedback)
							this.feedback = this.$t('feedbackMessage.resetPass')
					}
				).catch(error =>{
					console.log(error)
					this.feedback = error.response?.error?.message
					if(!this.feedback)
						this.feedback = this.$t('feedbackMessage.resetPass')
				})
				.finally(() => {
					if(this.feedback == this.$t('feedbackMessage.unotFound'))
						this.blocked.push(this.user.email.toLowerCase())
				});
			else
				this.feedback = this.$t('feedbackMessage.unotFound')
		}
	},
    togglePass() {
		this.sending = false
      this.showRecover = !this.showRecover;
	  this.feedback = ""
	  this.sending = false
    },
  },
  beforeRouteEnter(to, from, next) {
		window.scrollTo(0, 0);
		next();
	}
}
	
</script>


<template>
	<section class="wrapper--login" id="login" v-if="showRecover">

		<hgroup v-if="accountActive && !resetSenha && !passwordReset">
			<h1>{{ $t('signIn.accountActiveTitle') }}</h1>
			<p>{{ $t('signIn.accountActiveDesc') }}</p>
		</hgroup>

		<hgroup v-if="resetSenha">
			<h1>{{ $t('signIn.resetSenhaTitle') }}</h1>
			<p>{{ $t('signIn.resetSenhaDesc') }}</p>
		</hgroup>

		<hgroup v-if="passwordReset && !resetSenha">
			<h1>{{ $t('signIn.passwordResetTitle') }}</h1>
			<p>{{ $t('signIn.passwordResetDesc') }}</p>
		</hgroup>
		
		<hgroup v-if="!accountActive && !resetSenha && !passwordReset ">
			<h1>{{ $t('signIn.titleSign') }}</h1>
			<p>{{ $t('signIn.subTitleSign') }}</p>
		</hgroup>
			<form class="grid login">
				<span class="serverReturn" v-show="feedback">{{feedback}}</span>
				<div class="row">
					<FormInput :label="$t('form.labels.mail')" inputId="mail" inputType="email" :placeholder="$t('form.placeholder.email')" 
					:isRequired="true" :col="'col-100'" v-model="user.email " :isError="sending && !loginValidation.email" :messageError="$t('form.messageError.email')"/>
				
					<FormInput :label="$t('form.labels.password')" v-model="user.password" inputId="pass" inputType="password" :placeholder="$t('form.placeholder.password')" 
					:isRequired="true" :col="'col-100'" :isError="sending && !loginValidation.password" :messageError="$t('form.messageError.password')"/>
				
				</div>
				<div class="row pass">
					<Checkbox id="passRemenber" inputType="checkbox">{{$t('signIn.mailRemember')}}</Checkbox>
					<a class="linkRecover" @click="$event.preventDefault(); togglePass()">{{$t('signIn.forgotPass')}}</a>
				</div>
				
				<div class="row buttons--wrap">
					<AppButton @click="$event.preventDefault(); $router.push('/cadastre-se')" variant="secondary" to="/cadastre-se">{{$t('buttons.fazerCadastro')}}</AppButton>
					<AppButton @click="loginAct($event)" variant="primary">{{$t('buttons.entrar')}}</AppButton>
				</div>
			</form>
			
	</section>
	
	<section class="wrapper--login" id="recoverPass" v-if="!showRecover">
		<hgroup>
			<h1>{{$t('signIn.recuperarSenhaTitle')}}</h1>
			<p>{{$t('signIn.recuperarSenhaDesc')}}</p>
		</hgroup>
			<form class="grid login">
				<span class="serverReturn" v-show="feedback">{{feedback}}</span>
				<span class="serverReturn" v-show="mensagemReset">{{mensagemReset}}</span>
				<div class="row">
					<FormInput :label="$t('form.labels.mail')"
						:isError="sending && !resetValidation.email" 
						:messageError="$t('form.messageError.email')"
						:modelValue="user.email"
						v-model="user.email" inputId="mail" inputType="email" :placeholder="$t('form.placeholder.email')"
					:isRequired="true" :col="'col-100'"/>
				</div>	
				<div class="row buttons--wrap">
					<AppButton variant="secondary" @click="$event.preventDefault(); togglePass()">{{ $t('buttons.voltar') }}</AppButton>
					<AppButton @click="sendTokenEmail($event)" variant="primary">{{ $t('buttons.sendMail') }}</AppButton>
				</div>
			</form>
	</section>
</template>




<style lang="sass" scoped>
@use '../assets/sass/utilities/_colors' as *
@use '../assets/sass/utilities/_responsive' as *

.wrapper--login
	margin-block: 4rem
	width: min(100%, 500px)
	margin-inline: auto
	padding-inline: 1rem
	+respond-to($breakpoint-sm-min)	
		padding-inline: 0	

	hgroup
		margin-bottom: 3.125rem
		h1
			font-weight: 700
			font-size: clamp( 1.40rem, var(--clamp), 2rem)
		p
			font-size: 1rem

	.grid
		gap: 10px !important

		.row
			align-items: center

			&.pass
				flex-wrap: nowrap
				gap: 0
				justify-content: space-between

			.linkRecover
				text-decoration: underline
				color: var(--club--text-default)
				font-size: 14px
				white-space: nowrap
	
			&.buttons--wrap
				margin-top: 2rem
				display: flex
				flex-direction: row
				gap: 1rem
				align-items: center
				justify-content: center


</style>
	