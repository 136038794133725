import api from "./api";

const banners = fetch(`mocks${import.meta.env.VITE_BASE_PATH}banners.aproveite.json`)
var jsonFinal = "" 

class BannerAproveiteService {
  async get() {
    if(!jsonFinal){
      jsonFinal = await ((await banners).json())
    }
    return jsonFinal;
  }
}

export default new BannerAproveiteService();
