import ExperienciasService from '../services/experiencias.service';

export const experiencias = {
  namespaced: true,
  actions: {
    get({ commit }, filters) {
      if(!filters)
        filters = {}
      return ExperienciasService.get(filters);
    }
  },
  mutations: {
  }
};
