import api from "./api";

const banners = fetch(`mocks${import.meta.env.VITE_BASE_PATH}faq.json`)
var jsonFinal = "" 
class FaqService {
  async get() {
    if(!jsonFinal){
      jsonFinal = await ((await banners).json());
    }
    return jsonFinal
  }
}

export default new FaqService();
