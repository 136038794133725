import TagsService from '../services/tags.service';

export const tags = {
  namespaced: true,
  actions: {
    get({ commit }, filters) {
      return TagsService.get()
    }
  }
};
