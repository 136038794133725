<template>
    <nav id="nav--category">
        <div id="nav--category__content">
            <button class="linkCategory" :class="{'active': !idMenu}" @click="filterCards()">
                Todos
            </button>
            <button class="linkCategory" v-for="filter in categoryFilters" :key="filter.value" :class="{'active': idMenu == filter.id}" @click="filterCards(filter.id)">
                {{ filter.name }}
            </button>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'NavCategoryTutos',
    props: {
        categoryFilters: {
            type: Array,
            required: true
        }
    },
    data(){
        return{
            idMenu: ''
        }
    },
    methods: {
		filterCards(value) {
            this.idMenu = value
			this.$emit('filter', value);
		}
    }
};
</script>

<style lang="sass" scoped>
@use '../assets/sass/utilities/_colors' as *
@use '../assets/sass/utilities/_responsive' as *
@use '../assets/sass/utilities/_mixin' as *

#nav--category
    background-color: var(--lg-light-gray-300)
    padding-block: 1rem

    &__content
        margin-inline: auto
        width: min(100%, calc( var(--contentSize) -  10rem ))
        display: flex
        flex-direction: row
        justify-content: flex-start
        padding-inline: var(--padding-mobile)
        gap: 25px
        +respond-to($breakpoint-sm-min)
            justify-content: center
            --padding-mobile: 0

        @extend %scroll--mobile

        .linkCategory
            --bg: var(--lg-light-gray-200)
            --color: var(--black)

            color: var(--color)
            text-decoration: none
            border-radius: 1rem
            padding: 10px 15px
            background-color: var(--bg)
            font-size: 1rem
            text-transform: uppercase
            transition: all .23s ease
            white-space: nowrap


            &:hover, &.active
                --bg: var(--black)
                --color: var(--white)

</style>