import api from "./api";
import TokenService from "./token.service";
import router from '@/router'

class AuthService {
  login(user) {
    return api
      .post('/', {
        email: user.email,
        password: user.password,
        token: user.token
      })
      .then(response => {
        if (response.data?.data?.accessToken) {
          TokenService.setUser(response.data.data);
        }
        return response.data;
      });
  }

  token(username) {
    return api
      .post('users/forgot', {
        email: username
      })
      .then(response => {
        return response.data;
      });
  }

  reset(username, token) {
    return api
      .post('users/forgot', {
        email: username,
        //token: token
      })
      .then(response => {
        return response.data;
      });
  }

  setUser(user){
    return TokenService.setUser(user)
  }

  getCurrentUser(){
    return TokenService.getUser()
  }

  logout() {
    // api.get('auth/logout').then((response, status) => {
    //   if (status == 204)
    //     console.log("Logout success");
    // });
    TokenService.removeUser();
    router.push('/login'); 
  }

  registerMember(user) {
    return api.post('users/members', user);
  }

  resetPasswordToken(form) {
    return api.put('users/forgot', form);
  }

  tokenResetValidation(token) {
    return api.post('users/forgot/validate', token);
  }

  // async changeLang(id, lang){
  //   await api.put('users/'+id, {language: lang}).then(
  //     response =>{
  //       TokenService.setLang(response.data?.language);
  //       return response.data;
  //     }
  //   );
  // }
  refreshToken() {
    router.push('/login');
    return;
    // return api.get("/").then(response => {
    //   if(response.status == 401){
    //     TokenService.removeUser();
    //     router.push('/login');
    //     return response.data;
    //   }

    //   if (response.data.accessToken) {
    //     TokenService.setUser(response.data);
    //   }

    //   return response.data;
    // },
    // error =>{
    //   if(response.status == 401){
    //     TokenService.removeUser();
    //     router.push('/login');
    //     return response.data;
    //   }
    // }).catch(err =>{
    //   TokenService.removeUser();
    //   router.push('/login');
    //   return Promise.reject(error);
    // });
  }

  // refreshTokenAsync() {
  //   return api.get("auth/refresh");
  // }

  // async changeLang(id, lang){
  //   await api.put('users/'+id, {language: lang}).then(
  //     response =>{
  //       TokenService.setLang(response.data?.language);
  //       return response.data;
  //     }
  //   );
  // }
}

export default new AuthService();
