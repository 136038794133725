import api from "./api";

const banners = fetch(`mocks${import.meta.env.VITE_BASE_PATH}banners.home.json`)
var jsonFinal = "" 
class BannerHomeService {
  async get() {
    if(!jsonFinal){
      jsonFinal = await ((await banners).json());
    }
    return jsonFinal
  }
}

export default new BannerHomeService();
