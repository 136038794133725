import api from "./api";

const banners = fetch(`mocks${import.meta.env.VITE_BASE_PATH}banners.experiencia.json`)
var jsonFinal = "" 
class BannerExperienciaService {
  async get() {
    if(!jsonFinal){
      jsonFinal = (await ((await banners).json())).sort((a, b) => b.id - a.id)
    }
    return jsonFinal
  }
}

export default new BannerExperienciaService();
