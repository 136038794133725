import api from "./api";

class ContentsService {
  async get(filters = {}) {
    let params = "?";
    const { search, tag, limit } = filters;
    if (limit) params += "limit=" + limit + "&";
    if (search) params += "search=" + search + "&";
    if (tag) params += "tag=" + tag + "&";

    return api
      .get(`/contents${params}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

export default new ContentsService();
