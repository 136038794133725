import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router';
import store from "./store";
import setupInterceptors from './services/setupInterceptor';
import { i18n } from './locale/setup';
import FloatWhatsapp from './components/FloatWhatsapp.vue';
import Modal from './components/Modal.vue';

setupInterceptors(store);

const app = createApp(App);

app.use(router)
	.use(i18n)
	.use(store)
	.component('FloatWhatsapp', FloatWhatsapp)
	.component('Modal', Modal)
	.mount('#app');

// Track the initial page view for Facebook Pixel
if (window.fbq) {
	window.fbq('track', 'PageView');
}

// Track page views on route change for Facebook Pixel
router.afterEach((to, from) => {
	if (window.fbq) {
		window.fbq('track', 'PageView');
	}
});

