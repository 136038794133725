<template>
	<!-- <Loader v-if="loading"/> -->
	<FloatWhatsapp ref="floatWhatsapp" />
	<Modal />
	<RouterView />
</template>

<script>
import Loader from '@/components/Loader.vue';

  const ANALYTIC_TYPES = {
    META: {
      typeId: null,
      tag: null,
    },
    GA: {
      typeId: null,
      tag: null,
    },
    TK:{
      typeId: null,
      tag: null,
    },
    TEADS: {
      typeId: null,
      tag: null,
    },
  };

export default {
  components: { Loader },
  data() {
    return {
      loading: false,
      analytics: {},
      fbPixelId: null,
      tikTokId: null,
      gtmId: null,
      teadId: null
    };
  },
  created() { 
    this.$store.dispatch("analytics/getTypes").then((res) => {
      const { data } = res.data;
      this.analytics = data.reduce((acc, item) => {
        acc[item.id] = {
          tag: null,
          typeId: item.id,
          type: item.type,
        };
        ANALYTIC_TYPES[item.type].typeId = item.id;
        return acc;
      }, {});

      this.$store.dispatch("analytics/get").then((res) => {
      const { data } = res.data;
      for (const item of data) {
        this.analytics[item.analyticPageTypeId].tag = item.tag;
        if (ANALYTIC_TYPES.GA.typeId === item.analyticPageTypeId) {
          this.gtmId = item.tag;
        }
        if (ANALYTIC_TYPES.META.typeId === item.analyticPageTypeId) {
          this.fbPixelId = item.tag;
        }
        if (ANALYTIC_TYPES.TK.typeId === item.analyticPageTypeId) {
          this.tikTokId = item.tag;
        }
        if (ANALYTIC_TYPES.TEADS.typeId === item.analyticPageTypeId) {
          this.teadId = item.tag;
        }
      }
      this.gtmPixelCreate()
      this.fbPixelCreate()
      this.tkPixelCreate()
      this.teadPixelCreate()

		}).catch(e => {
			console.log('analytics error', e)
		})
  }).catch(e => {
    console.log('analytics types error', e)
  })

},
  methods: {
    tkPixelCreate(){

      if(!this.tikTokId)
        return;

      var self = this;
      !function (w, d, t) {
      w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
      var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
      ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
      try {
        ttq.load(self.tikTokId);
        ttq.page();
      } catch (error) {
        console.log('tiktok error', error)
      }

      }(window, document, 'ttq');
    },
    fbPixelCreate(){
      if(!this.fbPixelId)
        return;
       
      const noscriptPixelContent = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${this.fbPixelId}&ev=PageView&noscript=1" />`;
      const noscriptElement = document.querySelector('#noscript-pixel');
      noscriptElement.textContent = noscriptPixelContent;

      (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
      }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js'));
      try {
        fbq('init', this.fbPixelId);
        fbq('track', 'PageView');
      } catch (error) {
        console.log('fbq error', error)
      }
    },
    gtmPixelCreate(){

      if(!this.gtmId)
        return
      //START GTM 
      const gtmScript = document.createElement('script');
      gtmScript.setAttribute('async', true);
      gtmScript.setAttribute('id', 'gtm-script');
      gtmScript.setAttribute('src',`https://www.googletagmanager.com/gtag/js?id=${this.gtmId}`);
      document.head.appendChild(gtmScript);
      //END GTM
    },
    teadPixelCreate(){
      if(!this.teadId)
        return;

      const teadScript = document.createElement('script');
      teadScript.setAttribute('async', true);
      teadScript.setAttribute('id', 'tead-script');
      teadScript.setAttribute('type', 'text/javascript');
      teadScript.setAttribute('src',`https://p.teads.tv/teads-fellow.js`);
      document.head.appendChild(teadScript);
      window.teads_e = window.teads_e || [];
      window.teads_buyer_pixel_id = parseInt(this.teadId);
    },
    createReferrer(queryParams){
			const externalOriginKeyValues = {
				type: queryParams.get('extOrigin'),
				keyValues: [],
			};

			// Itera sobre os query params e verifica quais começam com "ext"
			queryParams.forEach((value, key) => {
				console.log(key)
				if (key.startsWith('ext') && key != 'extOrigin') {
					externalOriginKeyValues.keyValues.push({
						value: value,
						key: key.substring(3),
					});
				}
			});
			localStorage.setItem("fam-referrer", JSON.stringify(externalOriginKeyValues));
		},
    changeLanguage(event) {
      const lang = event.target.value;
      this.setLanguage(lang)
    },
    setLanguage(lang){
      this.$i18n.locale = lang;
      document.documentElement.lang = lang;
    }
  },
  mounted() {
    switch (import.meta.env.VITE_BASE_PATH) {
      case '/br/':
        this.setLanguage('pt-br')   
        break;
      case '/co/':
        this.setLanguage('co')   
        break;
      case '/ar/':
        this.setLanguage('ar')   
        break;
      case '/ec/':
        this.setLanguage('ec')   
        break;
      case '/cl/':
        this.setLanguage('cl')   
        break;
      case '/mx/':
        this.setLanguage('mx')   
        break;
      case '/pe/':
        this.setLanguage('pe')   
        break;
      case '/pa/':
        this.setLanguage('pa')   
        break;
      default:
        this.setLanguage('es') 
        break;
    }
    document.documentElement.lang = this.$i18n.locale;

    const queryParams = new URLSearchParams(this.$route.query);

		if (queryParams.has('extOrigin')) {
			this.createReferrer(queryParams)
		}
  },
};
</script>

<style src="@/assets/sass/app.sass" lang="sass"></style>
